import React from 'react';
import { forwardRef, useState } from 'react';
import { EyeSlashIcon, EyeIcon } from '@heroicons/react/20/solid';
import { InputBase } from './inputBase';
import PropTypes from 'prop-types';

export const InputPassword = forwardRef(function MyInput ( props, ref) {
  const [visible, setVisible] = useState(false);

  return (
    <InputBase
      {...props}
      type={visible ? 'text' : 'password'}
      ref={ref}
      addonAfter={
        visible ? (
          <div className="tw-p-2" onClick={() => setVisible(!visible)}>
            <EyeIcon className="tw-w-4 tw-h-4 tw-text-primary" />
          </div>
        ) : (
          <div className="tw-p-2" onClick={() => setVisible(!visible)}>
            <EyeSlashIcon className="tw-w-4 tw-h-4 tw-text-primary" />
          </div>
        )
      }
    />
  );
});

InputBase.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  addonBefore: PropTypes.element,
  addonAfter: PropTypes.element,
  error: PropTypes.string,
  value: PropTypes.string
};
