/*eslint-disable*/

import React, { useState } from 'react';
import { Listbox } from '@headlessui/react';
import PropTypes from 'prop-types';
import { usePopper } from 'react-popper';
import clsx from 'clsx';
import { CheckIcon, ChevronUpIcon } from '@heroicons/react/24/solid';

export const Select = ({
  options,
  multiple = false,
  className,
  onChange,
  initialOption,
}) => {
  const [selectedOption, setSelectedOption] = useState(
    initialOption ?? options[0]
  );
  const [buttonRef, setButtonRef] = useState(null);
  const [optionsRef, setOptionsRef] = useState(null);

  const handleChange = event => {
    setSelectedOption(event);
    onChange(event);
  };

  const { styles, attributes } = usePopper(buttonRef, optionsRef, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 6],
        },
      },
    ],
  });

  return (
    <Listbox
      value={selectedOption}
      onChange={handleChange}
      multiple={multiple}
      by={'value'}
    >
      {({ open }) => (
        <>
          <Listbox.Button
            ref={setButtonRef}
            className='tw-rounded-lg tw-p-2.5 tw-w-full tw-text-sm tw-text-white tw-bg-secondary tw-border tw-border-solid tw-border-primary tw-flex tw-justify-between tw-items-center'
          >
            {selectedOption.name}
            <ChevronUpIcon
              className={clsx(
                open ? '-tw-rotate-180' : 'tw-rotate-0',
                'tw-w-4 tw-h-4 tw-text-primary tw-duration-300'
              )}
            />
          </Listbox.Button>
          <Listbox.Options
            className={clsx(
              className,
              'tw-bg-secondary tw-rounded-lg tw-shadow tw-text-white tw-min-w-fit tw-max-h-64 tw-overflow-y-scroll tw-border tw-border-solid tw-border-primary scrollBar'
            )}
            style={styles.popper}
            {...attributes.popper}
            ref={setOptionsRef}
          >
            {options.map(option => (
              <Listbox.Option
                key={option.name}
                value={option}
                disabled={option?.unavailable}
                className='hover:tw-bg-primary/10 tw-p-2.5 tw-rounded-lg'
              >
                {({ selected }) => (
                  <div className='tw-grid tw-grid-cols-[25px_1fr] tw-items-center'>
                    {selected && (
                      <CheckIcon className='tw-text-primary tw-w-5 tw-h-5' />
                    )}
                    <span className='tw-col-start-2'>{option.name}</span>
                  </div>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </>
      )}
    </Listbox>
  );
};

Select.propTypes = {
  options: PropTypes.array,
  multiple: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func,
  initialOption: PropTypes.object,
};
