/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { Pagination, Autoplay } from 'swiper';
import PropTypes from 'prop-types';
import Provider from 'hooks';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useDeviceInfo } from 'hooks/useDeviceInfo';
import clsx from 'clsx';

const getBanners = async section => {
  const response = await Provider.get(`/banners?section=${section}`, {
    public: true,
  });
  return response;
};

export const Banners = ({ section, slidesDesktop = 1.5, className }) => {
  const [banners, setBanners] = useState([]);
  const { media } = useDeviceInfo();

  const classes = makeStyles(theme => ({
    root: {
      '& .swiper-pagination': {
        textAlign: 'left',
        paddingLeft: theme.spacing(4),
      },
    },
  }))();

  const breakpoints = {
    1284: {
      slidesPerView: slidesDesktop,
      spaceBetween: 12,
    },
    400: {
      slidesPerView: 1,
      spaceBetween: 8,
    },
  };

  useEffect(() => {
    getBanners(section).then(res => {
      setBanners(res);
    });
  }, [section]);

  if (banners.length < 1) return null;

  return (
    <Swiper
      autoplay={{ delay: 5000 }}
      pagination
      centeredSlides
      loop={banners.length === 1 ? false : true}
      breakpoints={breakpoints}
      modules={[Pagination, Autoplay]}
      className={clsx(classes.root)}
    >
      {banners.map((banner, k) => {
        if (media === 'mobile' && !banner?.url_mobile) return null;
        if (media === 'desktop' && !banner?.url) return null;
        return (
          <SwiperSlide key={k}>
            {({ isActive }) => (
              <Banner
                src={media === 'mobile' ? banner.url_mobile : banner.url}
                link={banner.link}
                active={isActive}
                className={className}
              />
            )}
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

Banners.propTypes = {
  section: PropTypes.string,
  slidesDesktop: PropTypes.number,
  className: PropTypes.string,
};

const Banner = ({ link, src, active, className }) => {
  return (
    <>
      {link ? (
        <NavLink to={link} className='tw-no-underline'>
          <img
            src={src}
            className={clsx(
              'tw-h-auto tw-w-full tw-object-contain tw-rounded-md',
              active ? 'tw-opacity-100' : 'tw-opacity-40',
              className
            )}
          />
        </NavLink>
      ) : (
        <div>
          <img
            src={src}
            className={clsx(
              'tw-h-auto tw-w-full tw-object-contain tw-rounded-md',
              active ? 'tw-opacity-100' : 'tw-opacity-40',
              className
            )}
          />
        </div>
      )}
    </>
  );
};

Banner.propTypes = {
  link: PropTypes.string,
  src: PropTypes.string,
  active: PropTypes.bool,
  className: PropTypes.string,
};
