/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react';
import Message from 'components/Wallet/Message';
// import { home } from '../routes';
// import useAppBar from 'hooks/useAppBar';
import useWallet from 'hooks/useWallet';
import useAuth from 'hooks/useAuth';
import useSocket from 'hooks/useSocket';
import { deposits_withdrawals } from 'pages/Wallet/routes';
import { PencilSquareIcon } from '@heroicons/react/24/solid';
import CurrencyInput from 'components/CurrencyInput';
import { toMoney } from 'helpers/format';
import { Button } from 'components/Tailwind/button/button';
import { InputMask } from 'components/Tailwind/Input/inputMask';
import { InputBase } from 'components/Tailwind/Input';
import { Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { Select } from 'components/Tailwind/select/select';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import { useTranslation } from 'react-i18next';

const MIN_AMOUNT = 2000;
const MAX_AMOUNT = 50000;

export const Withdrawal = () => {
  const { t } = useTranslation();
  const { currentUser } = useAuth();
  const government_id_formated =
    currentUser?.government_id?.replaceAll('.', '')?.replaceAll('-', '') ?? '';
  //   const { setAppBar } = useAppBar();
  const [reqHandler, setReqHandler] = useState({});
  const [payload, setPayload] = useState({
    type: 'pix',
    data: {
      type: 'cpfcnpj',
      pix: government_id_formated,
      formatted_pix: currentUser?.government_id ?? '',
      name: currentUser?.name ?? ''
    }
  });
  const [initial, setInitial] = useState(false);
  const [rejectedMessage, setRejectedMessage] = useState(false);
  const [confirmedMessage, setConfirmedMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const amountRef = useRef();
  const { payPix } = useWallet();
  const listen = useSocket();
  const [withdraw, setWithdraw] = useState('0,00');
  const [availableWithdraw] = useState(currentUser?.Wallet?.amountAward);

  useEffect(() => {
    return listen('azStatusTransfer', (eventName, status) => {
      const socketUserId = parseInt(eventName.split('/')[1]);

      console.log('statusstatus', status);
      if (status == 'ERROR' && socketUserId == currentUser.id) {
        setInitial(false);
        setRejectedMessage(true);
      }
      if (status == 'FINISHED' && socketUserId == currentUser.id) {
        setInitial(false);
        setConfirmedMessage(true);
      }
    });
    // eslint-disable-next-line
  }, [listen]);

  useEffect(() => {
    return listen('sicoobStatusTransfer', (eventName, status) => {
      const socketUserId = parseInt(eventName.split('/')[1]);

      if (['NÃO_REALIZADO', 'REJEITADO'].includes(status) && socketUserId == currentUser.id) {
        setInitial(false);
        setRejectedMessage(true);
      }
      if (status == 'FINALIZADO' && socketUserId == currentUser.id) {
        setInitial(false);
        setConfirmedMessage(true);
      }
    });
    // eslint-disable-next-line
  }, [listen]);

  useEffect(() => {

    if (reqHandler.status == 'Error') {
      setInitial(false);
      setRejectedMessage(true);
    }

  }, [reqHandler]);

  //   useEffect(() => setAppBar({ title: 'Saque', parent: home }), [setAppBar]);

  if (!currentUser?.government_id || !currentUser?.email) {
    return <UpdateProfile />;
  }

  if (currentUser.availableToWithdrawal === false) {
    return <Message message={t('modalWithdrawal.search_point')} />;
  }

  if (initial == true) {
    return <Message title={t('modalWithdrawal.waiting')} />;
  }

  if (rejectedMessage == true) {
    return (
      <Message
        title={t('modalWithdrawal.refused')}
        buttonName={'Voltar'}
        route={deposits_withdrawals}
      />
    );
  }

  if (confirmedMessage == true) {
    return (
      <Message
        title={t('modalWithdrawal.success')}
        buttonName={t('modalWithdrawal.success_back')}
        route={deposits_withdrawals}
      />
    );
  }

  const handleSetAmount = value => {
    if (value > MAX_AMOUNT) {
      setErrorMessage(t('modalWithdrawal.max_amount_message'));
      setPayload(prev => ({ ...prev, amount: 0 }));
      setWithdraw(value);
      return;
    }
    setErrorMessage('');
    setPayload(prev => ({ ...prev, amount: value }));
    setWithdraw(value);
  };

  const handleFinish = async () => {
    setIsLoading(true);
    setErrorMessage(null);
    try {
      const response = await payPix(payload);
      response.status == 'Error' && setReqHandler(response)
      setInitial(true);

    } catch (err) {
      setErrorMessage(err?.message);
    }
    setIsLoading(false);
  };

  const isValid =
    !!payload?.data?.type &&
    payload?.amount >= MIN_AMOUNT &&
    withdraw <= MAX_AMOUNT &&
    !!payload?.data?.pix &&
    !!payload?.data?.name;

  const handleFocus = () => {
    setErrorMessage(prev => {
      if (prev === t('modalWithdrawal.max_amount_message')) return '';
    });
    setWithdraw(null);
    setPayload(prev => ({ ...prev, amount: 0 }));
  };

  return (
    <div className="tw-py-10 tw-flex tw-justify-center">
      <div className="tw-space-y-2 tw-max-w-lg tw-flex tw-flex-col tw-items-center">
        <h1 className="tw-font-extrabold tw-text-3xl tw-mb-2">
          {t('modalWithdrawal.request_title')}
        </h1>
        <div className="tw-flex tw-items-center tw-gap-1">
          <p className="tw-font-extrabold tw-text-xl">
            {t('modalWithdrawal.request_description')}
          </p>
          <span className="tw-text-xl tw-break-all tw-max-w-xs">
            {payload?.data?.formatted_pix ?? ''}
          </span>
          <ModalEditPix setPayload={setPayload} />
        </div>
        <div className="tw-flex tw-gap-1">
          <p className="tw-font-extrabold">{t('modalWithdrawal.cpf')}:</p>
          <p>{currentUser.government_id}</p>
        </div>
        <p className="tw-text-center tw-mb-7">
          {t('modalWithdrawal.attention')}
        </p>
        <label className="tw-space-y-2">
          <span>{t('modalWithdrawal.drawal_amount')}</span>
          <CurrencyInput
            variant="outlined"
            value={withdraw}
            onFocus={handleFocus}
            onChange={handleSetAmount}
            inputRef={amountRef}
            fullWidth
          />
          <p>
            {t('modalWithdrawal.available_to_drawal')}{' '}
            {toMoney(availableWithdraw).props.children[2]}
          </p>
          <p className="tw-font-bold tw-text-danger-500">
            {t('modalWithdrawal.min_amount_message')} {toMoney(MIN_AMOUNT)}.
          </p>
        </label>
        {!!errorMessage && (
          <Alert className="tw-mt-2" severity="error">
            {errorMessage}
          </Alert>
        )}
        <Button
          size="xl"
          className="!tw-rounded-full"
          onClick={handleFinish}
          disabled={!isValid}
          loading={isLoading}
        >
          {t('modalWithdrawal.request_transfer')}
        </Button>
      </div>
    </div>
  );
};

const ModalEditPix = ({ setPayload }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [mask, setMask] = useState('000.000.000-00');
  const [pixKey, setPixKey] = useState('');
  const [pixKeyUnmasked, setPixKeyUnmasked] = useState('');
  const [pixType, setPixType] = useState({ name: 'cpf', value: 'cpfcnpj' });
  const [isValid, setIsValid] = useState(false);

  const options = [
    {
      id: 'cpfcnpj',
      name: 'Cpf',
      value: 'cpf'
    },
    {
      id: 'phone',
      name: 'Celular',
      value: 'phone'
    },
    {
      id: 'email',
      name: 'Email',
      value: 'email'
    },
    {
      id: 'other',
      name: 'Chave aleatória',
      value: 'other'
    },
    {
      id: 'cpfcnpj',
      name: 'Cnpj',
      value: 'cnpj'
    }
  ];

  const handleSelectChange = event => {
    if (event.value === 'cpf') setMask('000.000.000-00');
    if (event.value === 'phone') setMask('(00) 00000-0000');
    if (event.value === 'email') setMask('any');
    if (event.value === 'other') setMask('any');
    if (event.value === 'cnpj') setMask('00.000.000/0000-00');
    setPixKey('');
    setPixKeyUnmasked('');
    setPixType({ name: event.value, value: event.id });
  };

  const handleInputChange = value => {
    setIsValid(false);
    const unsmaskedValue = value
      .replaceAll('.', '')
      .replaceAll('-', '')
      .replaceAll('/', '')
      .replaceAll('(', '')
      .replaceAll(')', '')
      .replaceAll('_', '')
      .replaceAll(' ', '');
    if (pixType.name === 'cpf' && unsmaskedValue.length === 11)
      setIsValid(true);
    if (
      pixType.name === 'phone' &&
      unsmaskedValue.length >= 10 &&
      unsmaskedValue.length <= 11
    )
      setIsValid(true);
    if (
      pixType.name === 'email' &&
      value.includes('@') &&
      value.includes('.com')
    )
      setIsValid(true);
    if (pixType.name === 'other' && unsmaskedValue.length === 32)
      setIsValid(true);
    if (pixType.name === 'cnpj' && unsmaskedValue.length === 14)
      setIsValid(true);

    setPixKey(value);
    if (pixType.name === 'email' || pixType.name === 'other') {
      setPixKeyUnmasked(value);
      return;
    }
    setPixKeyUnmasked(unsmaskedValue);
  };

  const handleClick = () => {
    if (setPayload)
      setPayload(prev => {
        let data = Object.assign({}, prev.data);
        data.pix = pixKeyUnmasked;
        data.formatted_pix = pixKey;
        data.type = pixType.value;
        return { ...prev, data };
      });
    setMask('000.000.000-00');
    setPixKey('');
    setIsOpen(false);
  };

  return (
    <>
      <button onClick={() => setIsOpen(true)}>
        <PencilSquareIcon className="tw-w-4 tw-h-4 tw-text-primary" />
      </button>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        className="tw-relative tw-z-50"
      >
        <div
          className="tw-fixed tw-inset-0 tw-bg-gray-700/70"
          aria-hidden="true"
        />
        <div className="tw-fixed tw-inset-0 tw-flex tw-w-screen tw-items-center tw-justify-center">
          <Dialog.Panel className="tw-bg-[#1A202A] tw-rounded-lg tw-p-4 tw-max-w-sm lg:tw-max-w-xl tw-mx-6 md:tw-mx-0 tw-max-h-[466px] lg:tw-max-h-fit tw-overflow-y-auto tw-overflow-x-hidden tw-mt-12 lg:tw-mt-0">
            <Dialog.Title className="tw-flex tw-justify-end tw-w-full" as="div">
              <button onClick={() => setIsOpen(false)}>
                <XMarkIcon className="tw-w-7 tw-h-7 tw-text-gray-200 hover:tw-text-gray-400" />
              </button>
            </Dialog.Title>
            <Dialog.Description as="div">
              <div className="tw-flex tw-flex-col tw-items-center tw-gap-3 tw-max-w-xl tw-p-6">
                <h4 className="tw-text-lg tw-font-semibold">
                  Editar chave pix
                </h4>
                <div className="tw-flex tw-gap-2 tw-items-center">
                  <p>Escolha o tipo da chave pix:</p>
                  <div className="tw-w-36">
                    <Select
                      options={options}
                      onChange={handleSelectChange}
                      className="tw-z-10 !tw-overflow-y-hidden"
                    />
                  </div>
                </div>
                <InputMask
                  mask={mask}
                  label="Chave pix"
                  full
                  lazy={false}
                  value={pixKey}
                  onAccept={handleInputChange}
                />
                <Button onClick={handleClick} disabled={!isValid}>
                  Salvar pix
                </Button>
              </div>
            </Dialog.Description>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
};

const UpdateProfile = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [cpf, setCpf] = useState('');
  const [cpfError, setCpfError] = useState('');
  const {
    updateProfile,
    loading: { updateProfile: isLoadingUpdate },
    error: { updateProfile: errorMessageUpdate }
  } = useAuth();

  const handleClick = async () => {
    updateProfile({ government_id: cpf, email });
  };
  const handleEmailChange = e => {
    if (!e.target.value.includes('@') || !e.target.value.includes('.com')) {
      setEmailError(t('validations.invalid_email'));
      setEmail('');
      return;
    }
    setEmailError('');
    setEmail(e.target.value);
  };
  const handleCpfChange = e => {
    if (e.length !== 14) {
      setCpfError(t('validations.invalid_cpf'));
      setCpf('');
      return;
    }
    setCpfError('');
    setCpf(e);
  };

  return (
    <div className="tw-flex tw-flex-col tw-items-center">
      <div className="tw-py-20 tw-space-y-6 tw-max-w-xs md:tw-max-w-md tw-px-10">
        <p className="tw-text-center tw-text-xl tw-font-semibold">
          {t('modalWithdrawal.title')}
        </p>
        <p className="tw-text-sm">{t('modalWithdrawal.description')}</p>
        <InputMask
          mask="000.000.000-00"
          label="CPF"
          onAccept={handleCpfChange}
          error={cpfError}
        />
        <InputBase
          label="E-mail"
          onChange={handleEmailChange}
          error={emailError}
        />
        {!!errorMessageUpdate && (
          <Alert className="tw-mt-2" severity="error">
            {errorMessageUpdate}
          </Alert>
        )}
        <div className="tw-flex tw-justify-center">
          <Button
            onClick={handleClick}
            disabled={!email || !cpf}
            loading={isLoadingUpdate}
          >
            {t('modalWithdrawal.button')}
          </Button>
        </div>
      </div>
    </div>
  );
};

ModalEditPix.propTypes = {
  setPayload: PropTypes.func
};
