import React from 'react';
import PropTypes from 'prop-types';

export const Label = ({ label }) => {
  return (
    <label
      htmlFor={`floating_filled_${label}`}
      className={
        'tw-text-sm !tw-text-gray-600  tw-duration-300 !tw-transform -tw-translate-y-4 tw-scale-75 tw-top-4 tw-z-10 tw-origin-[0] tw-left-2.5 tw-absolute peer-focus:!tw-text-primary peer-placeholder-shown:tw-text-black peer-placeholder-shown:tw-scale-100 peer-placeholder-shown:tw-translate-y-0 peer-focus:tw-scale-75 peer-focus:-tw-translate-y-4'
      }
    >
      {label}
    </label>
  );
};

Label.propTypes = {
  label: PropTypes.string
};
