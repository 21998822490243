import { useState, useCallback } from 'react';
import Provider from './';
import { toQueryParams } from 'helpers';

export default function useWallet () {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [purchases, setPurchases] = useState();
  const [transactions, setTransactions] = useState();
  const [moves, setMoves] = useState();

  const getTransactions = useCallback(async params => {
    setIsLoading(true);
    setErrorMessage(null);
    try {
      const response = await Provider.get('/wallet/deposit_withdrawal' + toQueryParams(params), { data: 'paginate' });
      setTransactions(response.data);
      return response;
    } catch (err) {
      setTransactions([]);
      setErrorMessage(err?.message);
      throw err;
    } finally {
      setIsLoading(false);
    }
  }, []);

  const getPurchases = useCallback(async params => {
    setIsLoading(true);
    setErrorMessage(null);
    try {
      const response = await Provider.get('/wallet/transactions' + toQueryParams(params), { data: 'paginate' });
      setPurchases(response.data);
      return response;
    } catch (err) {
      setPurchases([]);
      setErrorMessage(err?.message);
      throw err;
    } finally {
      setIsLoading(false);
    }
  }, []);

  const getMoves = useCallback(async params => {
    setIsLoading(true);
    setErrorMessage(null);
    try {
      const response = await Provider.get('/wallet/moves' + toQueryParams(params), { data: 'paginate' });
      setMoves(response.data);
      return response;
    } catch (err) {
      setMoves([]);
      setErrorMessage(err?.message);
      throw err;
    } finally {
      setIsLoading(false);
    }
  }, []);

  const deposit = useCallback(async payload => {
    return await Provider.post('/wallet/deposits', payload);
  }, []);

  const chargePix = useCallback(async payload => {
    return await Provider.post('/wallet/pix/charge', payload);
  }, []);

  const sendDepositReceipt = useCallback(async (depositId, url) => {
    return await Provider.post(`/wallet/deposits/${depositId}/receipt`, { url, receiptType: 'image' });
  }, []);

  const withdrawal = useCallback(async payload => {
    return await Provider.post('/wallet/withdrawals', payload);
  }, []);

  const payPix = useCallback(async payload => {
    return await Provider.post('/wallet/pix/payment', payload);
  }, []);

  const charge = useCallback(async ({ amount, phone }) => {
    return await Provider.post('/charges', { amount, phoneNumber: phone });
  }, []);

  return {
    isLoading,
    errorMessage,
    transactions,
    getTransactions,
    purchases,
    getPurchases,
    moves,
    getMoves,
    deposit,
    chargePix,
    sendDepositReceipt,
    withdrawal,
    payPix,
    charge
  };
}