import React from 'react';
import PropTypes from 'prop-types';

import NumberFormat from 'react-number-format';

export default function NumberFormatComponent (props) {
  const { inputRef, onChange, value, convert, ...other } = props;

  let encoded = value;
  if (encoded && convert) {
    encoded /= convert;
  }

  return (
    <NumberFormat
      {...other}
      value={encoded}
      getInputRef={inputRef}
      onValueChange={val => {
        let decoded = val.value;
        if (convert) {
          decoded *= convert;
        }
        onChange(decoded, val);
      }}
    />
  );
};

NumberFormatComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  inputRef: PropTypes.func,
  convert: PropTypes.number
};