/*eslint-disable */
import React from 'react';
import { forwardRef } from 'react';
import { clsx } from 'clsx';
import { Error } from '../error';
import PropTypes from 'prop-types';
import { Label } from '../Label';

const INPUT_STATE = {
  default: {
    pattern: 'tw-ring-0',
    addonBefore: 'tw-border tw-border-primary tw-border-r-transparent',
    input: {
      style: 'focus:tw-ring-0 tw-border tw-border-primary',
      before:
        'tw-border-l tw-border-l-primary focus:tw-border-l-primary tw-rounded-l-none',
      after:
        'tw-border-r tw-border-r-primary focus:tw-border-r-primary tw-rounded-r-none',
    },
    addonAfter: 'tw-border tw-border-primary tw-border-l-transparent',
  },
  error: {
    pattern:
      'tw-border-danger-500 tw-border focus-within:tw-ring-danger-500 focus-within:tw-ring-2',
    addonBefore: 'tw-border tw-border-danger-500 tw-border-r-danger-500',
    input: {
      style:
        'tw-border-danger-500 focus:tw-border-danger-500 focus:tw-ring-danger-500 focus:tw-ring-1',
      before: 'tw-border-l tw-border-l-transparent tw-rounded-l-none',
      after: 'tw-border-r tw-border-r-transparent tw-rounded-r-none',
    },
    addonAfter: 'tw-border tw-border-danger-500 tw-border-l-danger-500',
  },
};

export const InputBase = forwardRef(function MyInput(props, ref) {
  const {
    label,
    className,
    type = 'text',
    addonBefore,
    addonAfter,
    error,
    value,
    defaultValue,
    full = false,
    shape = 'square',
    ...leftProps
  } = props;

  const style = INPUT_STATE[error ? 'error' : 'default'];

  return (
    <>
      <div
        className={clsx(
          'tw-flex tw-flex-1 tw-relative tw-bg-transparent',
          shape === 'rounded' ? 'tw-rounded-full' : 'tw-rounded-lg',
          full ? 'tw-w-full' : '',
          style.pattern,
          className
        )}
      >
        {addonBefore && (
          <div
            className={clsx(
              'tw-flex tw-items-center tw-justify-center tw-rounded-md tw-rounded-r-none tw-border-solid',
              shape === 'rounded' ? 'tw-rounded-l-full' : 'tw-rounded-l-lg',
              style.addonBefore
            )}
          >
            {addonBefore}
          </div>
        )}

        <input
          type={type}
          className={clsx(
            'tw-block tw-bg-transparent tw-px-6 tw-py-3.5 tw-w-full tw-h-full tw-text-sm tw-appearance-none focus:tw-outline-none focus:tw-ring-0 tw-peer disabled:tw-opacity-75 placeholder:tw-text-primary tw-border-solid',
            shape === 'rounded' ? 'tw-rounded-full' : 'tw-rounded-lg',
            style.input.style,
            addonBefore && style.input.before,
            addonAfter && style.input.after
          )}
          placeholder=' '
          value={value}
          defaultValue={defaultValue}
          {...leftProps}
          ref={ref}
          id={`floating_filled_${label}`}
        />

        {label && <Label float label={label} />}

        {addonAfter && (
          <div
            className={clsx(
              'tw-flex tw-items-center tw-justify-center tw-rounded-md tw-rounded-l-none tw-border-solid',
              shape === 'rounded'
                ? 'tw-rounded-r-full tw-pr-3 tw-pl-1'
                : 'tw-rounded-r-lg tw-px-1',
              style.addonAfter
            )}
          >
            {addonAfter}
          </div>
        )}
      </div>
      <Error message={error} />
    </>
  );
});

InputBase.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  addonBefore: PropTypes.element,
  addonAfter: PropTypes.element,
  error: PropTypes.string,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  full: PropTypes.bool,
  shape: PropTypes.string,
};
