import { colors } from '../colors';

export const Accordion = {
  root: {
    background: colors.white,
    fontSize: '1rem',
    color: '#0F0F0F',
    padding: '4px 22px',
    marginBottom: '10px',
    '&.MuiAccordion-rounded': {
      borderRadius: '5px'
    }
  }
};
