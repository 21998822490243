import React from 'react';
import { useEffect } from 'react';
import useScript from '../hooks/useScript';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  AntillephoneLogoPNR: {
    '& > div':{
      margin: '0 auto !important'
    }
  }
}));



const Antillephone = () => {
  const scriptStatus = useScript('https://8d11b8b5-19df-47cf-a199-df822cc0fe5d.snippet.antillephone.com/apg-seal.js');

  const classes = useStyles();

  useEffect(() => {
    if (scriptStatus === 'ready') {
      window.apg_8d11b8b5_19df_47cf_a199_df822cc0fe5d.init();
    }
  }, [scriptStatus]);

  return (
    <div
      id='apg-8d11b8b5-19df-47cf-a199-df822cc0fe5d'
      data-apg-seal-id='8d11b8b5-19df-47cf-a199-df822cc0fe5d'
      data-apg-image-size='256'
      data-apg-image-type='basic-light-large'
      className={classes.AntillephoneLogoPNR}
    ></div>
  );
};

export default Antillephone;
