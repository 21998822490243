import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from './component';

const FORMATS = [
  {
    size: 11,
    mask: '###.###.###-##'
  },
  {
    size: 14,
    mask: '##.###.###/####-##'
  }
];

const findFormat = len => {
  if (!len) {
    return null;
  }
  let current;
  FORMATS.forEach(({ size, mask }) => {
    if (!current && len <= size) {
      current = mask;
    }
  });
  return current || FORMATS[FORMATS.length - 1].mask;
};

function CpfCnpjFormatComponent ({ value, onChange, ...other }) {
  const decoded = value?.replace(/\+/, '');
  const [format, setFormat] = useState(findFormat(decoded?.length));

  useEffect(() => {
    setFormat(findFormat(decoded?.length));
  }, [decoded]);

  const handleKeyPress = () => {
    setFormat(findFormat(decoded?.length + 1));
  };

  const handleKeyUp = event => {
    const { keyCode } = event;
    if (keyCode === 8) {
      setFormat(findFormat(decoded?.length));
    }
  };

  // eslint-disable-next-line no-unused-vars
  const handleChange = (value, data) => {
    onChange(value);
  };

  return (
    <NumberFormat
      {...other}
      onChange={handleChange}
      value={value}
      format={format}
      mask="_"
      type="text"
      onBeforeInput={handleKeyPress}
      onKeyUp={handleKeyUp}
    />
  );
};

CpfCnpjFormatComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any
};

export default memo(CpfCnpjFormatComponent);
