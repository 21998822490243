import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import confetti from 'canvas-confetti';
import Winners from 'components/Bingo/Winners';
import { rand } from 'helpers';
import useFocus from 'hooks/useFocus';
// import { award } from 'mock';

const randomConfetti = size => confetti({
  angle: rand(125, 55),
  spread: rand(70, 50),
  particleCount: rand(size * 2, size),
  zIndex: 1299
});

const ConfettiContext = createContext();

export const ConfettiProvider = props => {
  const [data, setData] = useState({ open: false });
  const hasFocus = useFocus();

  const showConfetti = useCallback((award, configuration) => {
    if (award && typeof award === 'object') {
      setData({ duration: award.name == 'Acumulado' ? 16 : 8, interval: 2, size: 30, ...configuration, award, open: true });
      return () => setData(prev => ({ ...prev, open: false }));
    }
  }, []);

  useEffect(() => {
    if (data?.open) {
      const { interval, duration, size } = data;
      let intervalId = null;

      if (hasFocus) {
        intervalId = setInterval(() => randomConfetti(size), interval * 1000);
        randomConfetti(size);
      }

      const timeoutId = setTimeout(() => {
        clearInterval(intervalId);
        setData(prev => ({ ...prev, open: false }));
      }, duration * 1000 * data.award?.cardAwards?.length);

      return () => {
        clearInterval(intervalId);
        clearTimeout(timeoutId);
      };
    }
  }, [data, hasFocus]);

  return (
    <>
      <ConfettiContext.Provider {...props} value={{ showConfetti }} />
      <Winners open={data.open} data={data.award} delay={30} />
    </>
  );
};

const useConfetti = () => {
  const context = useContext(ConfettiContext);

  if (context === undefined) {
    throw new Error('useConfetti must be used within a ConfettiProvider');
  }

  return context;
};

export default useConfetti;
