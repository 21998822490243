import React from 'react';
import { useState } from 'react';
import { InputBase } from './inputBase';
import { IMaskMixin } from 'react-imask';
import PropTypes from 'prop-types';

const InputPhoneMix = IMaskMixin(({ inputRef, ...props }) => {
  return <InputBase {...props} ref={inputRef} />;
});

export const InputPhone = props => {
  const [mask, setMask] = useState('+55 (00) 00000-0000');

  const handleChange = value => {
    const phoneOriginalFormat = value
      .replace(/\(/, '')
      .replace(/\)/, '')
      .replace(/\-/, '')
      .replace(/\s/g, '');
    if (phoneOriginalFormat.length <= 13) setMask('+00 (00) 0000-00000');
    if (phoneOriginalFormat.length > 13) setMask('+00 (00) 00000-0000');
    props.onAccept(phoneOriginalFormat);
  };

  const handleKeyDown = event => {
    if (event.code === 'Backspace' && event.target.value.length < 6) {
      event.preventDefault();
      return;
    }
    if (event.code === 'Backspace' && event.target.selectionStart <= 4) {
      event.preventDefault();
      return false;
    }
    if (event.code === 'ArrowLeft' && event.target.selectionStart <= 4) {
      event.preventDefault();
      event.target.setSelectionRange(3,3);
      return;
    }
  };
  return (
    <InputPhoneMix
      {...props}
      onAccept={handleChange}
      mask={mask}
      onKeyDown={handleKeyDown}
    />
  );
};

InputPhoneMix.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  addonBefore: PropTypes.element,
  addonAfter: PropTypes.element,
  error: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func
};

InputPhone.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  addonBefore: PropTypes.element,
  addonAfter: PropTypes.element,
  error: PropTypes.string,
  value: PropTypes.string,
  onAccept: PropTypes.func
};


// phoneOriginalFormat.slice(1, 3) === '55'
//         ? phoneOriginalFormat
//         : `${phoneOriginalFormat.slice(0, 1)}55${phoneOriginalFormat.slice(1)}`