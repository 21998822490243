import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  header: {
    display: 'flex',
    alignItems: 'center'
  },
  code: {
    backgroundColor: ({ type }) => type ? theme.palette.custom[type]?.card : theme.palette.custom.card,
    color: 'white',
    borderRadius: '4px 0 0 0',
    display: 'inline-block',
    padding: props => props.small ? '2px 10px 0' : '4px 20px 0',
    fontSize: props => theme.typography.pxToRem(props.small ? 12 : 18),
    fontWeight: theme.typography.fontWeightBold
  },
  series: {
    backgroundColor: ({ type }) => type ? theme.palette.custom[type]?.card : theme.palette.custom.card,
    borderRadius: '0 4px 0 0',
    padding: props => props.small ? '0 4px' : '4px 10px 0',
    textTransform: 'uppercase',
    fontSize: theme.typography.pxToRem(14)
  },
  user: {
    flex: 1,
    padding: props => props.small ? '0 3px' : '0 7px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    textAlign: 'right',
    color: ({ type }) => type ? theme.palette.custom[type]?.card : theme.palette.custom.card,
    fontWeight: theme.typography.fontWeightBold
  },
  active: {
    color: 'white',
    backgroundColor: ({ type }) => type ? theme.palette.custom[type]?.number : theme.palette.primary.light
  },
  ball: {
    color: 'black'
  },
  body: {
    listStyleType: 'none',
    backgroundColor: ({ type }) => type ? theme.palette.custom[type]?.card : theme.palette.custom.card,
    display: 'flex',
    flexWrap: 'wrap',
    margin: 0,
    borderRadius: '0 4px 4px 4px',
    padding: props => props.small ? 2 : 5,
    '& > li': {
      width: props => `${100 / props.lengthX}%`,
      padding: props => props.small ? 1 : 2,
      '& > *': {
        backgroundColor: 'white',
        lineHeight: props => props.small ? '17px' : '30px',
        height: props => props.small ? 17 : 30,
        fontWeight: theme.typography.fontWeightBold,
        fontSize: props => theme.typography.pxToRem(props.small ? 12 : 18),
        borderRadius: 2,
        textAlign: 'center',
        '&$active': {
          backgroundColor: ({ type }) => type ? theme.palette.custom[type]?.number : '#FF6000',
          color: 'white'
        }
      }
    }
  }
}));
