import { colors } from '../colors';

export const Button = {
  contained: {
    '&:hover': {
      backgroundColor: colors.neutrals[100]
    }
  },
  outlined: {
    borderColor: colors.neutrals[100],
    color: colors.neutrals[100]
  },
  label: {
    '& > a': {
      all: 'none'
    }
  }
};
