/* eslint-disable */
import axios from 'axios';
import Auth from 'config/auth.js';

const Provider = axios.create( {
  baseURL: `${process.env.REACT_APP_API_URL}/v1`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
} );
Provider.interceptors.request.use( async config => {
  if ( !config.public ) {
    const response = await Auth.currentSession();
    config.headers.Authorization = response?.idToken?.jwtToken;
  }
  return config;
} );
Provider.interceptors.response.use(
  response => {
    //console.log( 'response interceptor', response )
    if ( response.config.data === 'paginate' ) {
      return {
        data: response.data,
        totalPages: response.headers['x-total-pages'],
        totalCount: response.headers['x-total-count']
      };
    }
    return response.data;
  },
  ( { response, message } ) => {
    if ( response ) {
      return Promise.reject( { status: response.status, ...response.data } );
    }
    return Promise.reject( { message } );
  }
);

export const ProviderSite = axios.create( {
  baseURL: `${process.env.REACT_APP_SITE_API}/api/`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  }
} );

ProviderSite.interceptors.request.use( async config => {
  try {
    const response = await Auth.currentSession();
    if ( response?.idToken ) {
      config.headers.Authorization = response?.idToken?.jwtToken;
    }
  } catch ( e ) { }

  return config;
} );

export default Provider;
