/* eslint-disable */
import { lazy } from 'react';

export const home = '/carteira';
// export const deposit = '/depositar';
export const withdrawal = '/sacar';
export const charge = '/recarga';
export const transactions = '/transacoes';
export const deposits_withdrawals = '/depositos_saques';
export const movements = '/movimentacoes';
export const account = '/conta';
export const preferences = '/preferencias';
export const paths = [
  home,
  withdrawal,
  charge,
  transactions,
  deposits_withdrawals,
  movements,
  account,
  preferences,
];

const routes = [
  // {
  //   key: 'deposit',
  //   path: deposit,
  //   component: lazy(() => import('./Deposit'))
  // },
  {
    key: 'withdrawal',
    path: withdrawal,
    component: lazy(() => import('./Withdrawal/index')),
  },
  {
    key: 'charge',
    path: charge,
    component: lazy(() => import('./Charge')),
  },
  {
    key: 'transactions',
    path: transactions,
    component: lazy(() => import('./Transactions')),
  },
  {
    key: 'deposits_withdrawals',
    path: deposits_withdrawals,
    component: lazy(() => import('./Deposit_Withdrawal')),
  },
  {
    key: 'movements',
    path: movements,
    component: lazy(() => import('./Movements')),
  },
  {
    key: 'account',
    path: account,
    component: lazy(() => import('./Account')),
  },
  {
    key: 'preferences',
    path: preferences,
    component: lazy(() => import('./Preferences')),
  },
  {
    key: 'home',
    path: home,
    component: lazy(() => import('./Home')),
  },
];

export default routes;
