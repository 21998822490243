/* eslint-disable */
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  message: {
    padding: theme.spacing(4),
    textAlign: 'center',
    // height: 'calc(100vh - 200px)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.text.primary,
  },
  icon: {
    marginBottom: theme.spacing(4),
    '& > img': {
      maxWidth: '100%',
      display: 'block',
    },
  },
  instruction: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
  },
}));
