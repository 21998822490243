/* eslint-disable */

import React, { useState, useCallback, useEffect, Suspense } from 'react';
import PropTypes from 'prop-types';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SignOutIcon from '@material-ui/icons/ExitToApp';
import useAuth from 'hooks/useAuth';
import { Link, useHistory } from 'react-router-dom';
import Share from 'components/Share';
import AppBar from 'components/AppBar';
import Header from './Header';
import Notifications from './Notifications';
import useStyles from './styles';
import MenuFooter from '../Footer';
import esportesIcon from '../../assets/icons/esportes.png';
import bingoIcon from '../../assets/icons/newBingo.png';
import cassinoIcon from '../../assets/icons/newCassino.png';
import loteriaPopularIcon from '../../assets/icons/newLoteriaPopular.png';
import { NavLink } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { roomMatches } from 'pages/Bingo/routes';
import useBingoRooms from 'hooks/useBingoRooms';
import { WithdrawalModal } from 'components/Wallet/WithdrawalForm/withdrawalComponents/withdrawalModal';
import { useTranslation } from 'react-i18next';
import { WalletIcon } from '@heroicons/react/24/solid';
import { MobileBottomMenu } from './mobileBottomMenu';

export default function SideMenuComponent({ data, children }) {
  const classes = useStyles();
  const { currentUser, isLoading, signOut } = useAuth();
  const { push } = useHistory();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width:640px)');

  const toggleDrawer = useCallback(
    to => () => setOpen(prev => to ?? !!prev),
    []
  );

  const handleLogout = useCallback(() => {
    signOut().then(() => {
      setOpen(false);
      push('/');
    });
  }, [signOut, push]);

  const { rooms, getAllRooms } = useBingoRooms();
  useEffect(getAllRooms, [getAllRooms]);

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
          #chat-widget-container {
            bottom: 68px !important;
          }
        `,
        }}
      />
      <AppBar onClick={toggleDrawer(true)} />
      {children}
      {isMobile && <MobileBottomMenu currentUser={currentUser} />}
      <MenuFooter useSuspense={false} />
      <SwipeableDrawer
        anchor='right'
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        PaperProps={{ className: classes.menu }}
      >
        <Header onClick={toggleDrawer(false)} />

        <Notifications
          data={currentUser?.notifications}
          onClick={toggleDrawer(false)}
        />

        <List className={classes.menuList}>
          {data?.map(({ key, path, label, icon, translate }) => {
            if (
              (!currentUser?.promocode && key == 'Afiliados') ||
              (currentUser?.roleName != 'SELLER' && key == 'Movimentações')
            ) {
              return null;
            } else if (currentUser?.partnerId == 3) {
              return (
                <React.Fragment key={key}>
                  {label === 'Sacar' ? (
                    <WithdrawalModal className='tw-w-full tw-text-left tw-px-4 tw-py-3 tw-text-base hover:tw-bg-[#3c3f43]' />
                  ) : (
                    <ListItem
                      button
                      component={Link}
                      to={path}
                      onClick={toggleDrawer(false)}
                      className={classes.listItem}
                    >
                      {!!icon && (
                        <ListItemIcon className={classes.listIcon}>
                          {icon}
                        </ListItemIcon>
                      )}
                      <ListItemText
                        primary={translate ? t(translate) : label}
                      />
                    </ListItem>
                  )}
                </React.Fragment>
              );
            } else {
              return (
                <React.Fragment key={key}>
                  {label === 'Sacar' ? (
                    <WithdrawalModal className='tw-w-full tw-text-left tw-px-4 tw-py-3 tw-text-base hover:tw-bg-[#3c3f43]' />
                  ) : (
                    <ListItem
                      key={key}
                      button
                      component={Link}
                      to={path}
                      onClick={toggleDrawer(false)}
                      className={classes.listItem}
                    >
                      {!!icon && (
                        <ListItemIcon className={classes.listIcon}>
                          {icon}
                        </ListItemIcon>
                      )}
                      <ListItemText primary={label} />
                    </ListItem>
                  )}
                </React.Fragment>
              );
            }
          })}
          <ListItem
            key={'resultados'}
            button
            component={Link}
            to={rooms && roomMatches(rooms[0]?.id)}
            onClick={toggleDrawer(false)}
            className={classes.listItem}
          >
            <ListItemText primary={t('menu.user.results')} />
          </ListItem>
        </List>

        <Share px={2} py={1} />

        {!!currentUser && (
          <List>
            <ListItem
              className={classes.listItem}
              button
              onClick={handleLogout}
              disabled={isLoading}
            >
              <ListItemIcon className={classes.listIcon}>
                <SignOutIcon color='primary' />
              </ListItemIcon>
              <ListItemText primary={t('menu.logout')} />
            </ListItem>
          </List>
        )}
      </SwipeableDrawer>
    </>
  );
}

SideMenuComponent.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      icon: PropTypes.element,
    })
  ),
};
