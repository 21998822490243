import { lazy } from 'react';

export const home = '/bingo';
export const roomDetail = (id = ':roomId') => `/bingo/${id}`;
export const roomMatches = (id = ':roomId') => `/bingo/${id}/partidas`;
export const ticket = (id = ':ticketId') => `/tickets/${id}`;
export const paths = [home, roomDetail(), roomMatches(), ticket()];

const routes = [
  {
    key: 'roomDetail',
    path: roomDetail(),
    component: lazy(() => import('./Room'))
  },
  {
    key: 'roomMatches',
    path: roomMatches(),
    component: lazy(() => import('./Matches'))
  },
  {
    key: 'ticket',
    path: ticket(),
    component: lazy(() => import('./Ticket'))
  },
  {
    key: 'home',
    path: home,
    component: lazy(() => import('./Rooms'))
  }
];

export default routes;