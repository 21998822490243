/* eslint-disable */
/* eslint-disable no-unused-vars */
import React, {
  useState,
  useCallback,
  useEffect,
  useContext,
  createContext,
} from 'react';
import PropTypes from 'prop-types';
import Loader from 'components/Loader';
import Auth from 'config/auth';
import pusher from 'config/push';
import { Authenticator } from 'aws-amplify-react';
import Provider, { ProviderSite } from './index';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import useUserLocation from './useUserLocation';

const currentURL = window.location.hostname;
const BackOfficeUrl =
  currentURL == 'localhost'
    ? 'https://i5qy580i01.execute-api.us-east-1.amazonaws.com/v1'
    : currentURL == 'pnr-web-dev.s3-website-us-east-1.amazonaws.com'
    ? 'https://i5qy580i01.execute-api.us-east-1.amazonaws.com/v1'
    : 'https://i5qy580i01.execute-api.us-east-1.amazonaws.com/v1';
const bodyRequest =
  currentURL == 'localhost'
    ? { phoneNumber: '+5521973138770', password: 'q1w2e3r4' }
    : currentURL == 'pnr-web-dev.s3-website-us-east-1.amazonaws.com'
    ? { phoneNumber: '+5521973138770', password: 'q1w2e3r4' }
    : { phoneNumber: '+5521999988888', password: 'devell@2021' };
const NAMESPACE = '@PNR:';
const TOKEN = 'TOKEN';
const Storage = window.localStorage;

export function getData(name) {
  return Storage.getItem(`${NAMESPACE}${name}`);
}

export function setData(name, value) {
  Storage.setItem(`${NAMESPACE}${name}`, value);
  return value;
}

export function clearData(name) {
  Storage.removeItem(`${NAMESPACE}${name}`);
}

export const getToken = () => getData(TOKEN);
export const setToken = value => setData(TOKEN, value);
export const clearToken = () => clearData(TOKEN);
const Provider2 = axios.create({
  baseURL: BackOfficeUrl,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});
const authContext = createContext();
const i18n = message => {
  if (/incorrect.*username.*password/i.test(message)) {
    return 'Usuário ou senha incorretos';
  }
  return message;
};
const useAuth = () => {
  const context = useContext(authContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
};
export function AuthProvider({ children }) {
  const auth = useAuthProvider();
  return (
    <Authenticator hideDefault>
      <authContext.Provider value={auth}>
        {auth.currentUser === undefined ? <Loader forceCenter /> : children}
      </authContext.Provider>
    </Authenticator>
  );
}
AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
function useAuthProvider() {
  const [loading, setLoading] = useState({});
  const [error, setError] = useState({});
  const [currentUser, setCurrentUser] = useState();
  const [userChannel, setUserChannel] = useState();
  const [depositModal, setDepositModal] = useState(false);

  let history = useHistory();

  const execute = useCallback(async (method, callback) => {
    setLoading(prev => ({ ...prev, [method]: true }));
    setError(prev => ({ ...prev, [method]: null }));
    try {
      return await callback();
    } catch (err) {
      const message = i18n(err.message);
      setError(prev => ({ ...prev, [method]: message }));
      throw err;
    } finally {
      setLoading(prev => ({ ...prev, [method]: false }));
    }
  }, []);

  const me = useCallback(async () => {
    try {
      const user = await Provider.get('/me');
      setCurrentUser(user);
    } catch (err) {
      setCurrentUser(null);
    }
  }, []);

  const signIn = useCallback(
    async ({ phoneNumber, password }) =>
      execute('signIn', async () => {
        const token = await Provider2.post('/backoffice/auth', bodyRequest);

        // console.log('token', token);
        setToken(token.data);

        const response = await Auth.signIn(phoneNumber, password)
          .then(r => {
            return r;
          })
          .catch(error => {
            if (error.code === 'UserNotConfirmedException') {
              const code = {
                pathname: '/cadastro/code',
                state: { phone: phoneNumber },
              };

              history.push(code);
              return;
            }
            throw error;
          });

        await me();
        return response;
      }),
    [execute, history, me]
  );

  const signUp = useCallback(
    async ({
      name,
      phoneNumber,
      password,
      birthDate,
      government_id,
      userName,
      email,
      promoCode,
      campaign_name,
    }) =>
      execute('signUp', async () => {
        await Provider.post(
          '/users',
          {
            phoneNumber,
            password,
            name,
            birthdate: birthDate,
            promocode: promoCode,
            government_id,
            userName,
            email,
            campaign_name,
          },
          { public: true }
        )
          .then(() => {
            localStorage.removeItem('affiliateKeySignup');
          })
          .catch(error => {
            if (error.message === 'Afiliado não encontrado') {
              localStorage.removeItem('affiliateKeySignup');
            }
            throw error;
          });
        return await signIn({ phoneNumber, password });
      }),
    [execute, signIn]
  );

  const updateProfile = useCallback(
    async ({ government_id, email }) =>
      execute('updateProfile', async () => {
        try {
          const response = await ProviderSite.post('/user/update-profile', {
            government_id,
            email,
          });
          if (response.status === 200)
            setCurrentUser(prev => ({
              ...prev,
              government_id: response.data.data.government_id,
              email: response.data.data.email,
            }));
          return response;
        } catch (e) {
          let message;
          if (e?.message) message = e.message;
          if (e?.request) message = e.request;
          if (e?.response?.data?.message) message = e.response.data.message;
          return Promise.reject({ message });
        }
      }),
    [execute]
  );

  const recoverPassword = useCallback(
    async phoneNumber =>
      execute('recoverPassword', async () => {
        return await Provider.post(
          '/users/recoverPassword',
          { phoneNumber },
          { public: true }
        );
      }),
    [execute]
  );

  const changePassword = useCallback(
    async ({ phoneNumber, code, password }) =>
      execute('changePassword', async () => {
        return await Provider.post(
          '/users/changePassword',
          { phoneNumber, code, password },
          { public: true }
        );
      }),
    [execute]
  );

  const changePasswordByProfile = useCallback(
    async ({ password, newPassword, confirmNewPassword }) =>
      execute('changePasswordByProfile', async () => {
        await ProviderSite.post('/user/update-password', {
          password,
          newPassword,
          confirmNewPassword,
        });
      }),
    [execute]
  );

  const changeUserAddress = useCallback(
    async ({ cep, address }) =>
      execute('changeUserAddress', async () => {
        await ProviderSite.post('/user/update-address', {
          cep,
          address,
        });
      }),
    [execute]
  );

  const signOut = useCallback(
    async () =>
      execute('signOut', async () => {
        await Auth.signOut();
        setCurrentUser(null);
        clearToken();
        // await window.altenarWSDK.set({
        //   token: '',
        // });
      }),
    [execute]
  );

  useEffect(() => me(), [me]);

  useEffect(() => {
    if (currentUser?.id) {
      const channelName = `user-${currentUser.id}`;
      const channel = pusher.subscribe(channelName);
      channel.bind('walletChange', user => setCurrentUser(user));
      if (process.env.NODE_ENV === 'development') {
        channel.bind_global((eventName, data) => {
          console.log('user', channelName, eventName, data);
        });
      }
      setUserChannel(channel);
      return () => pusher.unsubscribe(channelName);
    }
  }, [currentUser?.id]);

  return {
    loading,
    error,
    currentUser,
    userChannel,
    depositModal,
    setDepositModal,
    signIn,
    signUp,
    recoverPassword,
    changePassword,
    signOut,
    changePasswordByProfile,
    changeUserAddress,
    updateProfile,
  };
}
export default useAuth;
