/* eslint-disable */
export function detectOrientation() {
  let orientation =
    window.screen.orientation ||
    window.screen.mozOrientation ||
    window.screen.msOrientation;

  if (orientation) {
    if (orientation.type.startsWith('portrait')) {
      return 'vertical';
    } else if (orientation.type.startsWith('landscape')) {
      return 'horizontal';
    }
  }

  return 'Orientação desconhecida';
}
