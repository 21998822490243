/* eslint-disable */
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  toolbar: {
    [theme.breakpoints.down(1019)]: {
      justifyContent: 'space-between',
    },
  },
  links: {
    flexGrow: 1,
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    display: 'flex',
    justifyContent: 'space-evenly',
    height: '100%',
    alignItems: 'center',
  },
  link: {
    color: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    height: '100%',
    '&.active': {
      borderBottom: `4px solid ${theme.palette.primary.main}`,
      [theme.breakpoints.down(1019)]: {
        borderBottom: 'none',
      },
    },
    '&>span': {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
  },
  link_border: {
    borderRight: '1px solid rgba(255, 255, 255, 0.15)',
    height: 30,
  },
  menu: {
    borderBottom: '1px solid rgba(255, 255, 255, 0.17)',
    display: 'flex',
    zIndex: '1000',
    backgroundColor: '#131523',
  },
  menuActive: {
    visibility: 'visible',
    transition: 'all 0.5s',
  },
  menuHidden: {
    visibility: 'hidden',
    transition: 'all 0.5s',
    transform: 'translateY(-100%)',
  },
  userBalance: {
    marginTop: theme.spacing(),
    padding: theme.spacing(1),
    fontSize: 12,
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(12),
      padding: theme.spacing(1),
    },
  },
  userName: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(13),
      padding: theme.spacing(2),
    },
  },
  logo: {
    height: 64,
    [theme.breakpoints.down('sm')]: {
      height: 57,
    },
  },
  actions: {
    height: '100%',
    '& .MuiGrid-item': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  depositLink: {
    color: '#fff',
    textDecoration: 'none',
    display: 'flex',
    flexDirection: 'column',
    marginRight: '10px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  depositText: {
    color: theme.palette.primary.main,
  },
  mobileHeaderArea: {
    display: 'flex',
    marginRight: '4px',
  },
  langDropDown: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  flagMenu: {
    display: 'flex',
    gap: '20px',
  },
  menuContainer: {
    '& ul': {
      backgroundColor: '#23253A',
    },
  },
  flagArea: {
    // position: 'relative',
    // right: '-12px',
    width: 'fit-content',
  },
  typography: {
    fontSize: '12px',
    marginLeft: '8px',
    textTransform: 'capitalize',
  },
}));
