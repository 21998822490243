const menu = [
  // {
  //   key: 'Depositar',
  //   label: 'Depositar',
  //   path: '/depositar'
  // },
  {
    key: 'Sacar',
    label: 'Sacar',
    path: '/sacar',
    translate: 'menu.user.to_withdraw'
  },
  {
    key: 'Depósitos-Saques',
    label: 'Depositos/Saques',
    path: '/depositos_saques',
    translate: 'menu.user.deposists_withdraws'
  },
  {
    key: 'Transações',
    label: 'Transações',
    path: '/transacoes',
    translate: 'menu.user.transactions'
  },
  {
    key: 'Movimentações',
    label: 'Movimentações',
    path: '/movimentacoes',
    translate: 'menu.user.movements'
  },
  {
    key: 'Afiliados',
    label: 'Afiliados',
    path: '/afiliados',
    translate: 'menu.user.affiliates'
  }
];

export default menu;
