import { useState, useCallback, useEffect } from 'react';
import Provider from '.';
import useSocket from 'hooks/useSocket';

export default function useBingoRooms () {
  const [data, setData] = useState({});
  const [channel, setChannel] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const listen = useSocket();

  const getAllRooms = useCallback(async () => {
    setIsLoading(true);
    setErrorMessage(null);
    try {
      const rooms = await Provider.get('/rooms');
      setChannel('rooms');
      setData(prev => ({ ...prev, rooms }));
      return rooms;
    } catch (err) {
      setData(prev => ({ ...prev, rooms: [] }));
      setErrorMessage(err?.message);
      throw err;
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (channel) {
      return listen(channel, (eventName, match) => {
        switch (eventName) {
          case 'play':
          case 'close':
            setData(prev => {
              const index = prev.rooms?.findIndex(r => r.id === match.roomId);
              if (index >= 0) {
                if (match.status === 'started' || prev.rooms[index].currentMatch?.id === match.id) {
                  const rooms = [...prev.rooms];
                  rooms[index] = { ...rooms[index], currentMatch: match };
                  return { ...prev, rooms };
                }
              }
              return prev;
            });
            break;
        }
      });
    }
  }, [channel, listen]);

  return {
    isLoading,
    errorMessage,
    ...data,
    getAllRooms
  };
}