import { useCallback, useEffect, useState } from 'react';
import Geocode from 'react-geocode';

const useUserLocation = () => {
  Geocode.setApiKey('AIzaSyDsjWOlZmjm3a3yuR_VK0BlNALxq3me6TM');
  const [position, setPosition] = useState();
  const [address, setAddress] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [permission, setPermission] = useState();

  const handleAddressFromCoords = (lat, lng) => {
    Geocode.fromLatLng(lat, lng).then(
      response => {
        const addressInfo = response.results[0];
        const formattedAddress = addressInfo.formatted_address;

        const address = addressInfo.address_components.reduce(
          (acc, address_component) => {
            if (address_component.types.includes('route')) {
              acc.address = address_component.long_name;
            }

            if (address_component.types.includes('sublocality')) {
              acc.neighborhood = address_component.long_name;
            }

            if (address_component.types.includes('street_number')) {
              acc.street_number = address_component.long_name;
            }

            if (
              address_component.types.includes('administrative_area_level_2')
            ) {
              acc.city = address_component.long_name;
            }

            if (
              address_component.types.includes('administrative_area_level_1')
            ) {
              acc.state = address_component.long_name;
            }

            if (address_component.types.includes('country')) {
              acc.country = address_component.long_name;
            }

            if (address_component.types.includes('postal_code')) {
              acc.zip_code = address_component.long_name;
            }

            return acc;
          },
          {
            address: '',
            neighborhood: '',
            street_number: '',
            complement: '',
            city: '',
            state: '',
            country: '',
            zip_code: '',
            formattedAddress: ''
          }
        );

        address.formattedAddress = formattedAddress;
        setAddress(address);
        setPosition({ lat: lat, lng: lng });
        setIsLoading(false);
      },
      error => {
        console.error('Google info error:',error);
      }
    );
  };

  const handlePositionSuccess = useCallback(({ coords }) => {
    setPermission('granted');
    handleAddressFromCoords(coords.latitude, coords.longitude);
  }, []);

  const handlePositionError = err => {
    setPermission('denied');
    setIsLoading(false);
    console.log('unable to get user position: ' + err);
  };

  useEffect(() => {
    if (navigator && (window.location.hostname === 'localhost' || window.location.protocol === 'https:')) {
      navigator?.permissions?.query({ name:'geolocation' }).then(permission => {
        setPermission(permission.state);

        permission.onchange = () => {
          setPermission(permission.state);
        };

        navigator.geolocation.getCurrentPosition(function () {
          setPermission('granted');
        },function () {
          setPermission('denied');
        });
      }).catch(e => {
        console.log('navigator error',e);
        if (e.code === 1) {
          setPermission('dev');
        }
      });
      if (navigator?.permissions?.query) {
        navigator.geolocation.getCurrentPosition(
          handlePositionSuccess,
          handlePositionError
        );
      }

    } else {
      setPermission('dev');
    }
  }, [handlePositionSuccess]);

  return {
    position,
    address,
    isLoading,
    permission
  };
};

export default useUserLocation;
