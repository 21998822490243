import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from './component';

const FORMATS = [
  {
    size: 13,
    mask: '+## (##) #####-####'
  },
  {
    size: 12,
    mask: '+## (##) ####-####'
  }
];

const findFormat = len => {
  if (!len) {
    return null;
  }
  let current;
  FORMATS.forEach(({ size, mask }) => {
    if (!current && len <= size || len === 12) {
      current = mask;
    }
  });
  return current || FORMATS[FORMATS.length - 1].mask;
};

function PhoneFormatComponent ({ value, onChange, ...other }) {
  const decoded = value.replace(/\+/, '').replace(/\-/, '');
  const [format, setFormat] = useState(findFormat(decoded?.length));

  useEffect(() => {
    setFormat(findFormat(decoded?.length));
  }, [decoded]);

  const handleKeyPress = () => {
    if (decoded?.length === 13) return;
    setFormat(findFormat(decoded?.length + 1));
  };

  const handleKeyUp = event => {
    const { keyCode } = event;
    if (keyCode === 8) {
      setFormat(findFormat(decoded?.length));
    }
  };

  const handleChange = value => {
    if (value.includes('55')) return onChange(`+${value}`);
    onChange(`+55${value}`);
  };

  return (
    <NumberFormat
      {...other}
      onChange={handleChange}
      value={value}
      format={format}
      mask='_'
      type='tel'
      onBeforeInput={handleKeyPress}
      onKeyUp={handleKeyUp}
    />
  );
}

PhoneFormatComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any
};

export default memo(PhoneFormatComponent);
