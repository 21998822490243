/* eslint-disable */

import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { toCurrency } from 'helpers';

export default function CurrencyInputComponent({
  value,
  onChange,
  onBlur,
  ...other
}) {
  const parsedValue = value >= 0 ? toCurrency(value) : '';

  const handleChange = evt => {
    const { value } = evt.target;
    const number = Number(value.replace(/[^0-9]/g, ''));
    onChange(number || null);
  };

  const handleBlur = evt => {
    const { value } = evt.target;
    const number = Number(value.replace(/[^0-9]/g, ''));
    if (onBlur) onBlur(number || null);
  };

  return (
    <TextField
      {...other}
      value={parsedValue}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
}

CurrencyInputComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  value: PropTypes.any,
};
