import React, { useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import PropTypes from 'prop-types';
import { Dialog } from '@headlessui/react';
import { Withdrawal } from './withdrawal';
import { useTranslation } from 'react-i18next';

export const WithdrawalModal = ({ className }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(true);
  };

  return (
    <>
      <button className={`${className} tw-uppercase`} onClick={handleClick}>
        <span>{t('menu.user.to_withdraw')}</span>
      </button>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        className="tw-relative tw-z-50"
      >
        <div
          className="tw-fixed tw-inset-0 tw-bg-gray-700/70"
          aria-hidden="true"
        />
        <div className="tw-fixed tw-inset-0 tw-flex tw-w-screen tw-items-center tw-justify-center">
          <Dialog.Panel className="tw-bg-[#1A202A] tw-rounded-lg tw-p-4 tw-max-w-sm lg:tw-max-w-xl tw-max-h-[466px] lg:tw-max-h-fit tw-overflow-y-auto tw-overflow-x-hidden tw-mt-12 lg:tw-mt-0">
            <Dialog.Title className="tw-flex tw-justify-end tw-w-full" as="div">
              <button onClick={() => setIsOpen(false)}>
                <XMarkIcon className="tw-w-7 tw-h-7 tw-text-gray-200 hover:tw-text-gray-400" />
              </button>
            </Dialog.Title>
            <Dialog.Description as="div">
              <Withdrawal />
            </Dialog.Description>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
};

WithdrawalModal.propTypes = {
  className: PropTypes.string,
  closeMenu: PropTypes.func
};
