import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  header: {
    padding: theme.spacing(1.5, 2),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    textTransform: 'uppercase',
    textAlign: 'center',
    '& > .MuiTypography-root': {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightBold
    }
  },
  content: {
    textAlign: 'center'
  },
  dialogContent:{
    backgroundColor: '#f4f4f4'
  },
  actions: {
    padding: theme.spacing(8),
    justifyContent: 'center',
    backgroundColor: '#f4f4f4'
  },
  icon: {
    margin: theme.spacing(),
    color: theme.palette.primary.main,
    fontSize: 100
  },
  description: {
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: 0
  }
}));