/* eslint-disable */

export function detectDevice() {
  const userAgent = navigator.userAgent.toLowerCase();

  const mobileRegex =
    /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini|mobile/;
  const deviceInfo = {
    type: '',
    model: '',
  };

  if (mobileRegex.test(userAgent)) {
    deviceInfo.type = 'mobile';

    if (/android/.test(userAgent)) {
      const androidMatch = userAgent.match(/android\s([0-9.]*)/);
      deviceInfo.model = androidMatch
        ? `Android ${androidMatch[1]}`
        : 'Android';
    } else if (/iphone/.test(userAgent)) {
      deviceInfo.model = 'iPhone';
    } else if (/ipad/.test(userAgent)) {
      deviceInfo.model = 'iPad';
    } else if (/ipod/.test(userAgent)) {
      deviceInfo.model = 'iPod';
    } else if (/blackberry/.test(userAgent)) {
      deviceInfo.model = 'BlackBerry';
    } else if (/iemobile/.test(userAgent)) {
      deviceInfo.model = 'Windows Phone';
    } else if (/opera mini/.test(userAgent)) {
      deviceInfo.model = 'Opera Mini';
    } else {
      deviceInfo.model = 'Unknown Mobile';
    }
  } else {
    deviceInfo.type = 'desktop';

    if (/windows nt/.test(userAgent)) {
      const windowsMatch = userAgent.match(/windows nt ([0-9.]*)/);
      deviceInfo.model = windowsMatch
        ? `Windows ${windowsMatch[1]}`
        : 'Windows';
    } else if (/mac os x/.test(userAgent)) {
      const macMatch = userAgent.match(/mac os x ([0-9._]*)/);
      deviceInfo.model = macMatch
        ? `Mac OS X ${macMatch[1].replace(/_/g, '.')}`
        : 'Mac OS X';
    } else if (/linux/.test(userAgent)) {
      deviceInfo.model = 'Linux';
    } else if (/cros/.test(userAgent)) {
      deviceInfo.model = 'Chrome OS';
    } else {
      deviceInfo.model = 'Unknown Desktop';
    }
  }

  return deviceInfo;
}
