export const Container = theme => ({
  root: {
    paddingTop: theme.spacing(12),
    paddingBottom: theme.spacing(12),
    '&.no-space': {
      paddingTop: 0,
      paddingBottom: 0
    }
  }
});
