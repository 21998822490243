import React from 'react';
import moment from 'moment';

export const fill = (n, width = 2, z = '0') => {
  n = String(n);
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
};

export const toQueryParams = data => {
  if (typeof data !== 'object') {
    return '';
  }
  const ret = [];
  for (let d in data) {
    if (data[d] !== undefined) {
      const value = data[d]?.format ? data[d].format('YYYY-MM-DD') : data[d];
      ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(value));
    }
  }
  if (!ret.length) {
    return '';
  }
  return '?' + ret.join('&');
};

export const toShortDateAndTime = string => {
  if (typeof string !== 'string') {
    return;
  }
  const m = moment(string);
  if (!m.isValid()) {
    return;
  }
  let f = '';
  if (!moment().isSame(m, 'day')) {
    f += 'DD/MM ';
  }
  if (m.minutes()) {
    f += 'H:mm';
  } else {
    f += 'H[h]';
  }
  return m.format(f);
};

export const toDateAndTime = string => {
  if (typeof string !== 'string') {
    return;
  }
  const m = moment(string);
  if (!m.isValid()) {
    return;
  }
  let f = 'DD/MM ';
  if (m.minutes()) {
    f += 'H:mm[h]';
  } else {
    f += 'H[h]';
  }
  return m.format(f);
};

export const toDate = string => {
  if (typeof string !== 'string') {
    return;
  }
  const m = moment(string);
  if (!m.isValid()) {
    return;
  }
  let f = 'DD/MM ';

  return m.format(f);
};

export const msToTime = s => {
  const ms = s % 1000;
  s = (s - ms) / 1000;
  const secs = s % 60;
  s = (s - secs) / 60;
  const mins = s % 60;
  const hrs = (s - mins) / 60;

  const parts = [];

  if (hrs) {
    parts.push(hrs < 10 ? `0${hrs}` : hrs);
  }
  parts.push(mins < 10 ? `0${mins}` : mins);
  parts.push(secs < 10 ? `0${secs}` : secs);

  return parts.join(':');
};

export const toMoney = (number, ignoreDecimals) => {
  const formatter = Intl.NumberFormat('pt-BR', {
    style: 'decimal',
    minimumFractionDigits: ignoreDecimals ? 0 : 2
  });
  let acronym = '';
  let parsed = number / 100 || 0;
  if (parsed > 1000000) {
    parsed = parsed / 1000000;
    acronym = 'MM';
  }
  return (
    <>
      {/* {formatter.format(parsed)}{acronym} <small style={{ fontWeight: 'normal' }}>PNR</small> */}
      <span>R$</span> {formatter.format(parsed)}{acronym}
    </>
  );
};

export const toCurrency = (number, onlyInteger) => {
  const formatter = Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    maximumFractionDigits: onlyInteger ? 0 : 2
  });
  return formatter.format(number / 100 || 0);
};

export function toPhoneNumber (phone) {
  if (typeof phone !== 'string' || phone.indexOf('-') > 0) {
    return phone;
  }
  switch (phone.length) {
    case 8:
      return phone.replace(/(\d{4})(\d{4})/, '$1-$2');
    case 9:
      return phone.replace(/(\d{5})(\d{4})/, '$1-$2');
    case 10:
      return phone.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
    case 11:
      return phone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
    case 13:
      return phone.replace(/(\d{2})(\d{2})(\d{4})(\d{4})/, '$1 ($2) $3-$4');
    default:
      return phone.replace(/(\d{2})(\d{2})(\d{5})(\d{4})/, '$1 ($2) $3-$4');
  }
}
