export const Input = theme => ({
  root: {
    '& .MuiInputBase-root': {
      height: 34
    },
    '& .MuiFilledInput-root': {
      '&::before, &::after': {
        border:'none'
      },
      '& .MuiFilledInput-input': {
        padding: `${theme.spacing(3)}px ${theme.spacing(6)}px`,
        backgroundColor: theme.palette.common.white,
        borderRadius: theme.shape.borderRadius,
        color: theme.palette.secondary.main,
        fontSize:theme.typography.input.fontSize,
        fontWeight:theme.typography.input.fontWeight,
        lineHeight: theme.typography.input.lineHeight
      }
    },
    '& .MuiFilledInput-underline': {
      '&::after': {
        border: 'none'
      }
    }
  }
});
