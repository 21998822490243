export const colors = {
  white: '#FFF',
  orange: '#FC6301',
  blueDark: '#131523',
  neutrals: {
    0: '#00000',
    20: '#313131',
    90: '#D7DBEC',
    100: '#FFFFFF'
  }
};
