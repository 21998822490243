export const Chip = theme => ({
  root: {
    '& .MuiChip-label': {
      padding: `${theme.spacing(2)}px ${theme.spacing(5)}px`,
      fontSize: theme.typography.chip.fontSize,
      fontWeight: theme.typography.chip.fontWeight,
      lineHeight: theme.typography.chip.lineHeight
    },
    '& .MuiChip-deleteIcon': {
      fill: theme.palette.common.white
    }
  }
});
