/* eslint-disable */

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useStyle from './styles';
import flagPtBR from '../../assets/svg/flagPTBR.svg';
import flagEnUS from '../../assets/svg/flagENUS.svg';
import flagESP from '../../assets/svg/flagESP.svg';
import ButtonMui from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';

export const LanguageMenu = ({ withText = false, orientationMobile }) => {
  const { t, i18n } = useTranslation();
  const [controlFlag, setControlFlag] = useState(i18n.language);
  const [anchorEl, setAnchorEl] = useState(null);
  const style = useStyle();
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = lang => {
    i18n.changeLanguage(lang);
    setControlFlag(lang);
    setAnchorEl(null);
  };

  const languages = {
    'pt-BR': {
      flagIcon: flagPtBR,
      languageName: t('flagMenu.pt'),
    },
    'en-US': {
      flagIcon: flagEnUS,
      languageName: t('flagMenu.en'),
    },
    'es-ES': {
      flagIcon: flagESP,
      languageName: t('flagMenu.es'),
    },
  };

  return (
    <>
      <ButtonMui
        onClick={handleClick}
        style={{ padding: '0' }}
        className='!tw-min-w-12'
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
      >
        <img
          src={languages[controlFlag]?.flagIcon}
          className={`tw-w-auto tw-h-4 ${
            orientationMobile ? 'md:tw-h-4' : 'md:tw-h-5'
          }`}
        />
        {withText && (
          <Typography className={style.typography}>
            {languages[controlFlag]?.languageName}
          </Typography>
        )}
        <ArrowDropDownIcon />
      </ButtonMui>
      <Menu
        id='basic-menu'
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        keepMounted
        open={open}
        className={style.menuContainer}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <div className={style.menuContainer}>
          {Object.keys(languages).map(language => {
            return (
              <MenuItem
                key={language}
                onClick={() => handleClose(language)}
                className={style.flagMenu}
              >
                <img
                  src={languages[language].flagIcon}
                  className='tw-w-auto tw-h-5'
                />
                <span>{languages[language]?.languageName}</span>
              </MenuItem>
            );
          })}
        </div>
      </Menu>
    </>
  );
};

LanguageMenu.propTypes = {
  withText: PropTypes.bool,
  orientationMobile: PropTypes.bool,
};
