import axios from 'axios';

export const useGovCpf = () => {

  const searchCPF = async cpf => {

    const payload = {
      cpf: cpf
    };

    const result = await axios
      .post(process.env.REACT_APP_SITE_API + '/api/integration/legitimuz', payload)
      .then(response => response.data)
      .catch(err => {
        return err.response.status;
      });
    if (result) return result;
    return null;
  };

  return {
    searchCPF
  };
};
