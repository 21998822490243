/* eslint-disable */
import React, { Suspense } from 'react';
import routes from './routes';
import menu from './menu';
import SideMenu from 'components/SideMenu';
import RouteManager from 'components/RouteManager';
import useRouteScrollRestore from 'hooks/useRouteScrollRestore';
import 'swiper/swiper-bundle.min.css';

export default function Pages() {
  useRouteScrollRestore();
  return (
    <SideMenu data={menu}>
      {/*<Breadcrumb />*/}
      <RouteManager routes={routes} />
    </SideMenu>
  );
}
