/*eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

export const Error = ({ message }) => {
  return (
    <>{message && <p className='tw-text-danger-500 tw-mt-2'>{message}</p>}</>
  );
};

Error.propTypes = {
  message: PropTypes.string,
};
