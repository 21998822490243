/* eslint-disable */
import { useState, useEffect } from 'react';
import { detectOrientation } from '../helpers/screenOrientation';
import { detectDevice } from '../helpers/detectDevice';

export const useDeviceInfo = (watchOrientation = false) => {
  const [orientation, setOrientation] = useState(() => detectOrientation());

  const { type, model } = detectDevice();

  useEffect(() => {
    if (!watchOrientation) return;
    const handleOrientation = event => {
      if (event.target?.type.startsWith('landscape')) {
        setOrientation('horizontal');
        return;
      }
      setOrientation('vertical');
    };

    window.screen.orientation.addEventListener('change', handleOrientation);

    return () => {
      if (!watchOrientation) return;
      window.screen.orientation.removeEventListener(
        'change',
        handleOrientation
      );
    };
  }, []);

  let win = window,
    doc = document,
    docElem = doc.documentElement,
    body = doc.getElementsByTagName('body')[0],
    availableHeight =
      win.innerHeight || docElem.clientHeight || body.clientHeight;

  return {
    orientation: orientation,
    media: type,
    device: model,
    height: {
      realHeight: window.screen.availHeight,
      height:
        type === 'mobile' && orientation === 'vertical'
          ? availableHeight - 57
          : type === 'mobile' && orientation === 'horizontal'
          ? availableHeight
          : availableHeight - 64,
    },
  };
};
